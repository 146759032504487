<template>
  <div class="content">
    <PageHeader title="Adicionar Crédito" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header" role="button">
              Insira os dados
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)">
                    <i class="icon dripicons-chevron-down"></i>
                  </a>
                </li>
              </ul>
            </div>
            <form @submit.prevent="upgrade">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card card-border-info">
                      <div class="card-header">Adicionar créditos</div>
                      <div class="card-body">
                        <div class="form-group">
                          <label for="companyName">Valor a adicionar à sua conta</label>
                          <input type="text" class="form-control" v-model="form.value" id="companyName" required
                            v-money="money" v-bind:class="{ 'invalid': invalidValue }" />
                          <div class="invalid-field" v-if="invalidValue">{{ invalidValueMessage }}</div>
                          <small id="emailHelp3" class="form-text text-muted">O valor mínimo de R$ 100,00 e valor máximo
                            de R$ 5.000,00</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light">
                <button type="submit" class="btn btn-primary"
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending">{{
                    $t('generic-str.add') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showOverlay" class="overlay">
      <div class="modal-message">
        <p>Apenas contas pré-pagas podem emitir boletos.</p>
        <button class="btn btn-secondary" @click="closeOverlay">Entendi</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mask } from 'vue-the-mask';
import BillingService from '@/services/invoices.service';
import Collapse from '@/directives/Collapse';
import PageHeader from '@/components/PageHeader.vue';
import AccountService from '@/services/account.service';

export default {
  name: 'Billing',
  directives: {
    Collapse,
    mask,
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      cnpjValid: true,
      searching: false,
      isSending: false,
      invalidValue: false,
      invalidValueMessage: '',
      accountInfo: [],
      form: {
        value: '',
      },
      showOverlay: false, // Controla visibilidade do modal
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    fetch() {
      AccountService.getInfo().then((response) => {
        this.accountInfo = response;
      });
    },
    upgrade() {
      if (this.accountInfo.type !== 'prepaid') {
        this.showOverlay = true;
        return;
      }
      this.invalidValue = false;
      this.form.value = this.form.value.replace('R$', '');
      this.form.value = this.form.value.replace(',', '');
      this.form.value = this.form.value.replace('.', '');
      this.form.value = this.form.value.replace(' ', '');
      if (this.form.value < 100) {
        this.invalidValue = true;
        this.invalidValueMessage = 'O valor deve ser maior do que R$ 100,00';
        return;
      }
      if (this.form.value > 500000) {
        this.invalidValue = true;
        this.invalidValueMessage = 'O valor deve ser menor do que R$ 5.000,00';
        return;
      }
      this.isSending = true;
      if (!this.invalidValue) {
        BillingService.createInvoice({ value: parseFloat(this.form.value) })
          .then((rest) => {
            this.isSending = false;
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Conta atualizada',
              type: 'success',
            });
            this.$router.push('/invoices');
          })
          .catch((error) => {
            this.isSending = false;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.response.data.message,
              type: 'danger',
            });
          });
      }
    },
    closeOverlay() {
      this.showOverlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.modal-message {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.modal-message p {
  margin-bottom: 20px;
}
</style>
